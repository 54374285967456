<template>
  <div class="modal-ticket-escalation">
    <esmp-modal
      v-model="show"
      title="Эскалация"
      :width="800"
      ok-text="Ок"
      cancel-text="Отменить"
      class-name="modal-ticket-escalation"
      @on-ok="escalate"
    >
      <div class="escalation-form-element">
        <esmp-select v-model="reason" placeholder="Причина">
          <esmp-select-option
            v-for="item in reasons"
            :value="item.id"
            :label="item.name"
            :key="item.id"
          >
            {{ item.name }}
          </esmp-select-option>
        </esmp-select>
      </div>
      <div class="escalation-form-element">
        <esmp-datepicker-adaptive
          v-model="escalationDate"
          :disabled-dates="disableDates"
          is-time-selectable
        />
      </div>
      <div class="escalation-form-element">
        <hm-text-area
          placeholder="Комментарий"
          v-model="comment"
        />
      </div>
    </esmp-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { mapActions } from 'vuex';
import HmTextArea from '@/components/hm-form/controls/HmTextArea.vue';

export default {
  name: 'ModalTicketEscalation',
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    sourceSystem: {
      type: String,
      default: '',
    },
    ticketId: {
      type: [String, Number],
      default: undefined,
    },
  },
  components: {
    HmTextArea,
  },
  data() {
    return {
      reason: null,
      reasons: [],
      comment: '',
      escalationDate: dayjs().add(1, 'day').toDate(),
    };
  },
  computed: {
    show: {
      get() {
        return this.isShow;
      },
      set(newValue) {
        this.$emit('hide-modal', { modalName: this.$options.name, newValue });
      },
    },
  },
  methods: {
    ...mapActions('system', ['setLoading']),
    disableDates(date) {
      return date.getTime() < Date.now();
    },
    escalate() {
      this.$API.escalation.escalate(this.ticketId, {
        sourceSystem: this.sourceSystem,
        reasonId: this.reason,
        date: +dayjs(this.escalationDate),
        description: this.comment,
      }).then(() => {
        this.$EsmpNotify.$show('Эскалация запрошена', 'success');
      });
    },
  },
  created() {
    this.$API.escalation.getEscalationReasons({
      source: this.sourceSystem,
      ticketId: this.ticketId,
    }).then(({ data }) => {
      this.reasons = data;
    });
  },
};
</script>

<style lang="scss" scoped>
.esmp-modal-body {
  font-size: 16px;
}
.escalation-form-element {
  margin: 20px 0 0 0;
  &:first-child {
    margin: 0;
  }
}
</style>
